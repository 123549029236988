<template>
  <div class="select-por-page col-1 md:col-1 lg:col-1">
    <select
      class="p-inputtext p-component"
      style="appearance: revert !important; width: 100% !important"
      v-model="pagina"
      @change="this.$emit('changePagina', pagina);"
    >
      <option v-for="a in itens" :key="a" :value="a">
        {{ a }}
      </option>
    </select>
  </div>
</template>

<script>
import pagina from "@/class/pagina";

export default {
  name: 'selectporpagina',
  props: {
    qdt: {},
  },
  data() {
    return {
      itens:[10,20,30,40,50], 
      pagina:20
    }
  },
  beforeMount(){ 
    /*if(this.qdt != null){ this.pagina = this.qdt}
    const pg = pagina.pg;
    if(pg){
      this.pagina = pg
    }*/
    this.pagina = this.qdt
    if(this.pagina == null){
      this.pagina = pagina.pg
    }
    pagina.pg = this.pagina
    pagina.routePg = "&pg="+this.pagina
  },
  methods: {
    
  }
}
</script>
<style scoped>
.select-por-page{
  width: 80px;
  margin-left: 35px;
}
</style>
  
  
  